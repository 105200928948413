import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  createUserStart,
  createUserSuccess,
  createUserFail,
  updateUserStart,
  updateUserSuccess,
  updateUserFail,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFail,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  fetchCurrentUserFail,
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFail,
  fetchAllUsersFail,
  fetchAllUsersStart,
  fetchAllUsersSuccess,
  updateUserInfoStart,
  updateUserInfoSuccess,
  updateUserInfoFail,
} from './actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';

export type UserCreateRequest = {
  email: string;
  role: string;
  firstName: string;
  lastName: string;
};

export type UserUpdateRequest = {
  id: number;
  firstName: string;
  lastName: string;
  role: string;
};

export type UserUpdateInfoRequest = {
  firstName: string;
  lastName: string;
};

const API_URL = '/users';

export const fetchUser = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchUserStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserFail(err.response.data.error));
    });
};

export const fetchUsers = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchUsersStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUsersFail(err.response.data.error));
    });
};

export const fetchAllUsers = () => (dispatch: Dispatch) => {
  dispatch(fetchAllUsersStart());
  return axios
    .get(API_URL)
    .then((response) => {
      dispatch(fetchAllUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllUsersFail(err.response.data.error));
    });
};

export const createUser =
  (inputs: UserCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createUserStart());
    return axios
      .post(API_URL, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createUserSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.USER_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createUserFail(err.response.data.message));
      });
  };

export const updateUser =
  (inputs: UserUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateUserStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, {
        ...inputs,
      })
      .then(() => {
        dispatch(updateUserSuccess());
        showToast(translate(intl, 'SUCCESS.USER_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserFail(err.response.data.message));
      });
  };

export const updateUserInfo =
  (inputs: UserUpdateInfoRequest) => (dispatch: Dispatch) => {
    dispatch(updateUserInfoStart());
    return axios
      .patch(`${API_URL}/update-info`, {
        ...inputs,
      })
      .then((response) => {
        dispatch(updateUserInfoSuccess());
        dispatch(fetchCurrentUserSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateUserInfoFail(err.response.data.message));
      });
  };

export const deleteUser =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteUserStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteUserSuccess());
        showToast(translate(intl, 'SUCCESS.USER_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteUserFail(err.response.data.message));
      });
  };

export const fetchCurrentUser = () => (dispatch: Dispatch) => {
  dispatch(fetchCurrentUserStart());

  return axios
    .get(`${API_URL}/me`)
    .then((response) => {
      dispatch(fetchCurrentUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCurrentUserFail());
    });
};
